<template>
  <div class="loading">
    <breeding-rhombus-spinner
      :animation-duration="1500"
      :size="30"
      :color="color"
    />
  </div>
</template>

<script>
import { BreedingRhombusSpinner } from 'epic-spinners'

export default {
  components: { BreedingRhombusSpinner },
  props: {
    color: {
      type: String,
      default: '#16b666'
    }
  }
}
</script>

<style lang="sass" scoped>
.loading
  display: flex
  margin-top: 0
  width: 100%
  height: 100%
  justify-content: center
  align-items: center
  flex-direction: column
  font-size: 1.5rem

span
  margin-top: 3rem
</style>
